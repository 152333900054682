// export const BASE_URL = 'https://edunix-api.beetronix.com/';
// export const BASE_URL = 'https://api.alnukhbeh-edu.com/'
// export const BASE_URL = 'https://syria-flowers.beetronix.com/'
// export const BASE_URL = 'https://api.mirror.edunixsystem.com/'
export const BASE_URL = 'https://api.alawalschool.edunixsystem.com'

// export const IMAGE_URL = 'https://syrian-flowers-manager.beetronix.net/files/logo.png';
export const IMAGE_URL = 'https://api.alawalschool.edunixsystem.com/files/logo.png'

export const VERSION = '5.0.1';


// api.ags-sy.com
// api.ags-sy.com/files/logo.png